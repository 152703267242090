import { effect, inject, Injectable, untracked } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@app/window.token';
import { AuthLogin, RefreshTokenPostResponse } from '@models/auth.model';
import { Nullable } from '@models/nullable.model';
import { AuthApiService } from '@services/data-access/entities/auth-api.service';
import { AuthStateService } from '@state-management/auth-state';
import { SystemStateService } from '@state-management/system-state';
import { parseJWT } from '@utils/jwt-manipulation';
import {
  catchError,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { GoogleAnalyticsService } from './google-analytics.service';
import { IndexedDBService } from './indexed-db/indexed-db.service';
import { MeService } from './me.service';
import { ServiceWorkerService } from './service-worker.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly stateService = inject(StateService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly serviceWorkerService = inject(ServiceWorkerService);
  private readonly router = inject(Router);
  private readonly authApiService = inject(AuthApiService);
  private readonly authStateService = inject(AuthStateService);
  private readonly meService = inject(MeService);
  private readonly indexedDbService = inject(IndexedDBService);
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
  private readonly window = inject(WINDOW);

  constructor() {
    this.logoutAfterFlushStatesEffect();
  }

  login(data: AuthLogin): Observable<RefreshTokenPostResponse> {
    return this.authApiService.postObtainToken(data).pipe(
      tap(({ access, refresh }) => {
        this.authStateService.setMultipleValues({
          access,
          refresh,
        });
      }),
      tap(({ refresh }) => {
        this.setExpirationDateFromToken(refresh);
      }),
      switchMap((result) => {
        return this.meService.initMeStates().pipe(
          catchError(() => of(null)),
          map(() => result),
        );
      }),
    );
  }

  logout(): void {
    this.stateService.flushStates();
    this.googleAnalyticsService.createEvent('logout', { user: undefined });
  }

  refreshToken(refreshToken: string): Observable<RefreshTokenPostResponse> {
    return this.authApiService.postRefreshToken(refreshToken).pipe(
      tap(({ access, refresh }) => {
        this.authStateService.setMultipleValues({
          access,
          refresh,
        });
      }),
      tap(({ refresh }) => this.setExpirationDateFromToken(refresh)),
    );
  }

  setExpirationDateFromToken(token: Nullable<string>): void {
    if (!token) {
      this.authStateService.resetValue('tokenExpiredAt');
      return;
    }

    const result = parseJWT(token, this.window.atob);
    if (!result) return;

    this.authStateService.setValue('tokenExpiredAt', result.exp);
  }

  private logoutAfterFlushStatesEffect(): void {
    effect(() => {
      const flushStatesEvent = this.stateService.flushStatesEvent();
      if (!flushStatesEvent) return;

      untracked(() => {
        localStorage.clear();
        this.router.navigate(['']);

        const isUpdateAvailable =
          this.systemStateService.getValue('isUpdateAvailable')();
        this.deleteDB$(isUpdateAvailable).subscribe();
      });
    });
  }

  private deleteDB$(isUpdateAvailable: boolean): Observable<void> {
    const deleteDB$ = this.indexedDbService.deleteAllData().pipe(
      take(1),
      catchError(() => of(null)),
    );
    const clearCache$ = this.serviceWorkerService.clearCache().pipe(
      take(1),
      catchError(() => of(null)),
    );

    return forkJoin([deleteDB$, clearCache$]).pipe(
      take(1),
      map(() => {
        return;
      }),
      catchError(() => {
        return of();
      }),
      finalize(() => isUpdateAvailable && this.window.location.reload()),
    );
  }
}
