import { effect, inject, Injectable, untracked } from '@angular/core';
import { LocalStorageEnum } from '@enums/local-storage.enum';
import {
  MeGetResponse,
  MePermissionsGetResponse,
  PermissionEntity,
  PermissionOperation,
} from '@models/me.model';
import { Nullable } from '@models/nullable.model';
import { AuthStateService } from '@state-management/auth-state';
import { initialState } from '@state-management/me-config-state/me-config-state.config';
import { MeConfigStateService } from '@state-management/me-config-state/me-config-state.service';
import { MeProfileStateService } from '@state-management/me-profile-state/me-profile-state.service';
import { SystemStateService } from '@state-management/system-state';
import { debouncedSignal } from '@utils/debounce-signal';
import { IsEqualObject } from '@utils/object-manipulation';
import {
  catchError,
  finalize,
  forkJoin,
  Observable,
  of,
  take,
  tap,
} from 'rxjs';
import { MePermissionsStateService } from '../state-management/me-permissions-state/me-permissions-state.service';
import { MeApiService } from './data-access/entities/me-api.service';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class MeService {
  private readonly authStateService = inject(AuthStateService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly meApiService = inject(MeApiService);
  private readonly meProfileStateService = inject(MeProfileStateService);
  private readonly meConfigStateService = inject(MeConfigStateService);
  private readonly mePermissionsStateService = inject(
    MePermissionsStateService,
  );
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
  private readonly meStateDebounced = debouncedSignal(
    this.meConfigStateService.state,
  );

  private readonly isMeInfoFetched =
    this.systemStateService.getValue('isMeInfoFetched');

  constructor() {
    this.propagateMeConfigStateEffect();
    this.loadDataInLocalStorage();
  }

  initMeStates(): Observable<
    Nullable<[Nullable<MeGetResponse>, Nullable<MePermissionsGetResponse>]>
  > {
    const isLogged = !!this.authStateService.getValue('tokenExpiredAt')();
    if (!isLogged) return of(null);

    return this.loadToStateMe();
  }

  resetMeStates(): void {
    this.meProfileStateService.resetState();
    this.meConfigStateService.resetState();
    this.mePermissionsStateService.resetState();
    this.authStateService.resetState();
    this.systemStateService.setValue('isMeInfoFetched', false);
    this.mePermissionsStateService.resetState();
  }

  private loadToStateMe(): Observable<
    [Nullable<MeGetResponse>, Nullable<MePermissionsGetResponse>]
  > {
    const me$ = this.loadToStateMeProfileAndMeConfig();
    const permissions$ = this.loadToStateMePermissions();

    return forkJoin([me$, permissions$]).pipe(
      take(1),
      finalize(() => this.systemStateService.setValue('isMeInfoFetched', true)),
    );
  }

  private loadToStateMeProfileAndMeConfig(): Observable<
    Nullable<MeGetResponse>
  > {
    return this.meApiService.getMe().pipe(
      take(1),
      tap(({ app_config: meConfig, ...meProfile }) => {
        this.meProfileStateService.setMultipleValues(meProfile);
        this.meConfigStateService.setMultipleValues(meConfig);
      }),
      tap(({ id, email, corporate_id, corporate_name }) =>
        this.googleAnalyticsService.createEvent('login', {
          user: { id, email, corporate_id, corporate_name },
        }),
      ),
      catchError(() => of(null)),
    );
  }

  private loadToStateMePermissions(): Observable<
    Nullable<MePermissionsGetResponse>
  > {
    return this.meApiService.getMePermissions().pipe(
      take(1),
      tap((permissions) => {
        permissions.forEach((permission) => {
          const [entity, operation] = permission.split('.') as [
            PermissionEntity,
            PermissionOperation,
          ];

          this.mePermissionsStateService.entity(entity).set(operation, true);
        });
      }),
      catchError(() => of(null)),
    );
  }

  private propagateMeConfigStateEffect(): void {
    effect(() => {
      const isMeInfoFetched = this.isMeInfoFetched();
      const meConfigState = this.meStateDebounced();
      const isOffline = this.systemStateService.getValue('isOffline')();

      if (IsEqualObject(meConfigState, initialState)) return;

      if (!isMeInfoFetched || isOffline) return;

      untracked(() => {
        this.meApiService
          .modifyMeAppConfig(meConfigState)
          .pipe(take(1))
          .subscribe();
      });
    });
  }

  private loadDataInLocalStorage(): void {
    effect(() => {
      this.setCorporateIDInLocalStorage();
      this.setAppConfigInLocalStorage();
      this.setRefreshInLocalStorage();
      this.setIsOfflineInLocalStorage();
      this.setLimitsInLocalStorage();
      this.setLastDownloadHistogramsParamsInLocalStorage();
      this.setPermissionsInLocalStorage();
      this.setLastDownloadDateInLocalStorage();
    });
  }

  private setLastDownloadDateInLocalStorage(): void {
    const lastDownloadDate =
      this.systemStateService.getValue('lastDownloadDate')();
    if (!lastDownloadDate) {
      localStorage.removeItem(LocalStorageEnum.LastDownloadDate);
      return;
    }

    localStorage.setItem(
      LocalStorageEnum.LastDownloadDate,
      JSON.stringify(lastDownloadDate),
    );
  }

  private setLastDownloadHistogramsParamsInLocalStorage(): void {
    const lastDownloadHistogramsParams = this.systemStateService.getValue(
      'lastDownloadHistogramsParams',
    )();
    if (!lastDownloadHistogramsParams) {
      localStorage.removeItem(LocalStorageEnum.LastDownloadHistogramsParams);
      return;
    }

    localStorage.setItem(
      LocalStorageEnum.LastDownloadHistogramsParams,
      JSON.stringify(lastDownloadHistogramsParams),
    );
  }

  private setLimitsInLocalStorage(): void {
    const limits = this.meProfileStateService.getValue('limits')();
    const isMeInfoFetched =
      this.systemStateService.getValue('isMeInfoFetched')();
    if (!isMeInfoFetched) {
      localStorage.removeItem(LocalStorageEnum.UserLimits);
      return;
    }

    localStorage.setItem(LocalStorageEnum.UserLimits, JSON.stringify(limits));
  }

  private setPermissionsInLocalStorage(): void {
    const permission = this.mePermissionsStateService.state();
    const isMeInfoFetched =
      this.systemStateService.getValue('isMeInfoFetched')();
    if (!isMeInfoFetched) {
      localStorage.removeItem(LocalStorageEnum.UserPermissions);
      return;
    }

    localStorage.setItem(
      LocalStorageEnum.UserPermissions,
      JSON.stringify(permission),
    );
  }

  private setIsOfflineInLocalStorage(): void {
    const isOffline = this.systemStateService.getValue('isManualOffline')();

    localStorage.setItem(LocalStorageEnum.IsOffline, isOffline + '');
  }

  private setRefreshInLocalStorage(): void {
    const refresh = this.authStateService.getValue('refresh')();
    if (!refresh) {
      localStorage.removeItem(LocalStorageEnum.Refresh);
      return;
    }

    localStorage.setItem(LocalStorageEnum.Refresh, refresh + '');
  }

  private setCorporateIDInLocalStorage(): void {
    const corporateID = this.meProfileStateService.getValue('corporate_id')();
    if (!corporateID) {
      localStorage.removeItem(LocalStorageEnum.Corporate);
      return;
    }

    localStorage.setItem(
      LocalStorageEnum.Corporate,
      JSON.stringify(corporateID),
    );
  }

  private setAppConfigInLocalStorage(): void {
    const appConfig = this.meConfigStateService.state();
    const isMeInfoFetched =
      this.systemStateService.getValue('isMeInfoFetched')();
    if (!isMeInfoFetched) {
      localStorage.removeItem(LocalStorageEnum.AppConfig);
      return;
    }

    localStorage.setItem(LocalStorageEnum.AppConfig, JSON.stringify(appConfig));
  }
}
