import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@app/window.token';

interface GADataLayerPushParams {
  event?: GAEvents;
  [key: string]: unknown;
}

interface GADataLayer {
  push: (obj: GADataLayerPushParams) => void;
}

type GAEvents = 'login' | 'logout';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private readonly window = inject(WINDOW);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly dataLayer = (this.window as any)?.dataLayer as GADataLayer;

  createEvent<T extends object>(
    eventName?: GAEvents,
    params: T = {} as T,
  ): void {
    this.updateDataLayer({
      ...params,
      event: eventName,
    });
  }

  updateDataLayer<T extends GADataLayerPushParams>(params: T = {} as T): void {
    this.dataLayer.push(params);
  }
}
