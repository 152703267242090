import { AuthTokenServerInfo } from '@models/auth.model';
import { Nullable } from '@models/nullable.model';

/**
 * Method which converts the JWT access token into a JWTInfo format with the main logged user information
 * Annotation: only works with the access token
 * @param token token in jwt format to parse into JWTInfo
 * @returns an object with the JWTInfo data or null if is not possible to parse the token
 */
export function parseJWT<TokenInfo extends object = AuthTokenServerInfo>(
  token: string,
  atobFn: (input: string) => string,
): Nullable<TokenInfo> {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atobFn(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch {
    return null;
  }
}
